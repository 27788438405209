<template>
  <div class="main-1">
    <img class="background" src="@/assets/start-background.png" alt="background">
    <div class="content">
      <div class="text">
        <h1 class="title animate" v-bind:class="{visible: showText}">Tu Tienda de Barrio Online</h1>
        <h3 class="subtitle animate" v-bind:class="{visible: showText}">Hagamos que tu negocio evolucione ¡Integrate
          al mundo digital!</h3>
      </div>
      <div class="images">
        <img class="auris" v-bind:class="{visible: showAuris}" src="@/assets/start-auris.png" alt="auris">
        <img class="auris" v-bind:class="{visible: showRemera}" src="@/assets/start-remera.png" alt="remera">
        <img class="auris" v-bind:class="{visible: showZapato}" src="@/assets/start-zapato.png" alt="zapato">
      </div>
      <router-link to="/wizard" class="btn-orange animate _wizard" v-bind:class="{visible: showText}">¡Comenzá gratis!
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Start',
  mounted() {
    this.showText = true
    let count = 0
    setInterval(() => {
      const mod = count++ % 3;
      this.showAuris = mod === 0
      this.showRemera = mod === 1
      this.showZapato = mod === 2
    }, 2000)
  },
  data() {
    return {
      showText: false,
      showAuris: false,
      showRemera: false,
      showZapato: false
    }
  }
}
</script>
<style scoped>

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.animate {
  transform: translateY(20%);
}

@media (min-width: 768px) {
  .animate {
    transition: 0.4s linear;
    opacity: 0;
  }
}

.animate.visible {
  transform: translateY(0);
  opacity: 1;
}

.nav > .content {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1000px;
}

.main-1 {
  width: 100%;
  height: 100vh;
  background: #41B2E9;
  color: white;
  position: relative;
}

@media (max-width: 768px) {
  .main-1 {
    height: unset;
  }
}

.main-1 > .background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}


.auris.visible {
  transform: translateX(0);
  opacity: 1;
}


.images {
  position: relative;
  max-height: unset;
  width: 320px;
  height: 300px;
  z-index: 20;
}

.auris {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  transition: .5s;
  transition-delay: .5s;
  opacity: 0;
  height: 100%;
  width: 100%;
  transform: translateX(0);
}

@media (min-width: 768px) {

  .images {
    position: absolute;
    right: 0;
    bottom: 60px;
    max-height: 70%;
    width: 60%;
    height: 100%;
  }

  .auris {
    transform: translateX(10%);
    width: 100%;
    max-width: 600px;
    /*height: 400px;*/
  }
}

@media (min-width: 1600px) {
  .auris {
    width: initial;
    height: initial;
  }

  .images {
    bottom: 180px;
  }
}


.main-1 .content {
  position: relative;
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-bottom:50px
}



.title {
  margin: 160px 0 0;
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  color: #FFFFFF;
  font-size: 28px;
  line-height: 35px;
}

.subtitle {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .main-1 .content {
    align-items: flex-start;
    padding-bottom: 0px;
  }

  .text{
    width: 40%;
  }

  .title{
    font-size: 52px;
    line-height: 65px;
  }

  .subtitle{
    font-size: 28px;
    line-height: 34px;
  }

  .btn-orange{
    font-size: 18px;
    line-height: 22px;
  }
}

.btn-orange {
  font-family: Merriweather;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  padding: 20px 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #F8671B;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  font-style: normal;
  color: white;
  font-size: 13px;
  line-height: 17px;
}

.main-3 > .story-1 > .content {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

h2 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 55px;
  color: white;
  margin: 0;
}

</style>
