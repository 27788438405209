<template>
  <div class="main">
    <img class="headphones" src="./../../assets/AURIS.png" data-scroll/>
    <img class="phone" src="./../../assets/handphone.png" data-scroll/>
    <div class="content">
      <h2 class="blue-title" data-scroll>¿ Por qué elegirnos ?</h2>
      <ul class="details">
        <li class="offer-item" data-scroll>
          <div class="offer">
            <span class="title">Tenemos Experiencia</span>
            <span class="description">Somos plataforma. Hace más de 10 años brindamos soluciones propias para la integración y transformación digital de procesos internos y externos de pequeños, medianos y grandes negocios, partiendo de la comercialización online.</span>
          </div>
        </li>
        <li class="offer-item" data-scroll>
          <div class="offer">
            <span class="title">Hacemos equipo con el cliente</span>
            <span class="description">Promovemos soluciones que dan lugar a la óptima administración del TIEMPO Y LA INFORMACION. Somos conscientes de su VALOR, son los recursos más ricos de esta era y del futuro, por sobre los económicos.</span>
          </div>
        </li>
        <li class="offer-item" data-scroll>
          <div class="offer">
            <span class="title">Construímos una comunidad de negocios abierta</span>
            <span class="description">Integramos las cadenas de valor de los negocios en una RED DIGITAL que facilita la comunicación entre proveedores y compradores, adoptando una nueva identidad sistémica de propiedad colectiva que derivará en un mercado más eficiente</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Reasons'
}
</script>
<style scoped>
@media (min-width: 1200px) {
  .headphones[data-scroll] {
    transition: 1s;
    transform: translateX(300px);
    opacity: 0;
  }

  .headphones[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .phone[data-scroll] {
    transition: 1s;
    transition-delay: 1s;
    transform: translateY(100%);
    opacity: 0;
  }

  .phone[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }

  .blue-title[data-scroll] {
    transition: 1s;
    transition-delay: 1s;
    transform: scaleX(2) scaleY(2);
    opacity: 0;
  }

  .blue-title[data-scroll="in"] {
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }

  .offer-item[data-scroll] {
    transition: 0.5s;
    transition-delay: 1s;
    transform: translateY(100px);
    opacity: 0;
  }

  .offer-item[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .headphones {
    display: none;
  }

  .phone {
    display: none;
  }
}

.details {
  max-width: 600px;
  margin-bottom: 150px;
}

/*.message {*/
/*  position: absolute;*/
/*  right: 12%;*/
/*  top: 30%;*/
/*}*/

.headphones {
  position: absolute;
  top: 20%;
  left: 55%;
  max-width: 600px;
}

.phone {
  position: absolute;
  bottom: 0;
  right: -60px;
  max-width: 650px;
}

@media (max-width: 1200px) {
  .headphones {
    display: none;
  }

  .phone {
    display: none;
  }
}

.offer-item {
  margin-top: 25px;
  list-style-image: url("./../../assets/list-marker.png");
}

.offer-item:first-child {
  margin-top: 0;
}

.description {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
  color: #385398;
}

@media(min-width: 768px){
  .description{
    font-size: 16px;
    line-height: 22px;
  }
}

.title {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: rgba(51, 70, 119, 0.9);
}

@media(min-width: 768px){
  .title{
    font-size: 28px;
    line-height: 34px;
  }
}

.offer {
  display: flex;
  flex-direction: column;
}

.blue-title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  margin-top: 100px;
  color: #385398;
}

@media (min-width: 768px) {
  .blue-title{
    font-size: 42px;
    line-height: 55px;
  }
}

.content {
  position: relative;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  /*height: 100%;*/
  margin: auto auto;
  display: flex;
  flex-direction: column;
}

.main {
  position: relative;
  width: 100%;
  /*height: 100vh;*/
  padding-top: 100px;
  background: #f5f5f5;
  overflow: hidden;
}

</style>
