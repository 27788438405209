import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        storeName: "",
        storeColor: "ffffff",
        template: {
            "id": "8d57cd5418404e35a538a341",
            "name": "Alimentos y Bebidas",
            "catalog": "b4d8815dc7c4cc4a43894d7d",
            "owner": "2"
        }
    },
    mutations: {
        updateName(state, newName) {
            state.storeName = newName
        },
        updateColor(state, newColor) {
            state.storeColor = newColor
        },
        updateTemplate(state, newTemplate) {
            state.template = newTemplate;
        }
    }
})

export default store
