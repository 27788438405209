<template>
  <div class="index" style="position: relative">
    <nav class="nav">
      <div class="content">
        <img class="hamburger" src="../../assets/hamburger.png" @click="toggleMenu"/>
        <a href="#" @click="scrollTop">
          <img class="logo-header" src="../../assets/nubing-nav-logo.png"/>
        </a>
        <a href="https://www.nubing.net/login" class="login _enter">Ingresar</a>
      </div>
    </nav>
    <Start/>
    <JoinUs/>
    <Stories/>
    <Reasons/>
    <NewSteps/>
    <NewThey/>
    <NewReasons/>
    <FAQ/>
    <!--    <Domain/>-->
    <NewNumbers/>
    <Finish/>
    <div class="footer">
      <div class="footer-content">
        <img class="logo-footer" src="../../assets/nubing-nav-logo.png"/>
        <div>
          <span class="footer-links">
            <a href="https://www.nubing.net/register" class="boring-link _enter" data-scroll>Crear Cuenta</a>
          <a href="https://www.nubing.net/contact" class="boring-link _contact" data-scroll>Contacto</a>
          <a href="https://www.nubing.net/plans" class="boring-link" data-scroll>Planes</a>
          </span>

          <a href="#" @click="openNew('https://www.instagram.com/nubingok')"><i class="fab fa-instagram"></i></a>
          <a href="#" @click="openNew('https://www.facebook.com/nubing')"><i class="fab fa-facebook"></i></a>
        </div>
      </div>
    </div>
    <div class="overlay" v-bind:class="{ active: openMenu }">
    </div>
    <div class="menu-content" v-bind:class="{ active: openMenu }">
      <div class="scrollable">
        <div class="menu-item _wizard">
          <router-link to="/wizard">
            <div>
              Creá tu tienda
            </div>
          </router-link>
        </div>
        <div class="menu-item">
          <a class="_enter" href="https://www.nubing.net/login">
            <div>
              Ingresar
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="_enter" href="https://www.nubing.net/register">
            <div>
              Registrarme
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a href="https://www.nubing.net/recovery/forgotpassword">
            <div>
              Olvidé mi contraseña
            </div>
          </a>
        </div>
        <div class="menu-item" v-bind:class="{ active: openPlans }" @click="togglePlans">
          <div>
            Planes <i class="fas fa-chevron-down"></i> <i class="fas fa-chevron-up"></i>
          </div>
        </div>
        <div class="collapse"  v-bind:class="{ active: openPlans }">
          <div class="menu-item">
            <a href="https://www.nubing.net/plans?type=SHOPS_PLAN">
              <div>
                Tiendas online y físicas
              </div>
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.nubing.net/plans?type=COMMUNICATION_PLAN">
              <div>
                Comunicación
              </div>
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.nubing.net/plans?type=CONTENT_PLAN">
              <div>
                Contenido
              </div>
            </a>
          </div>
        </div>
        <div class="menu-item"  v-bind:class="{ active: openSolutions }"  @click="toggleSolutions">
          <div>
            Soluciones <i class="fas fa-chevron-down"></i> <i class="fas fa-chevron-up"></i>
          </div>
        </div>
        <div class="collapse"  v-bind:class="{ active: openSolutions }">
          <div class="menu-item">
            <a href="https://www.nubing.net/application/directory?family=SALES_FAMILY">
              <div>
                Multiplicá tus ventas
              </div>
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.nubing.net/application/directory?family=CONNECTION_FAMILY">
              <div>
                Administra tu negocio
              </div>
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.nubing.net/application/directory?family=CONTROL_FAMILY">
              <div>
                Controlá tu gestión
              </div>
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.nubing.net/application/directory?family=MANAGE_FAMILY">
              <div>
                Conectate
              </div>
            </a>
          </div>
        </div>
        <div class="menu-item">
          <a href="https://www.nubing.net/tutorials">
            <div>
              Tutoriales
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a href="https://www.nubing.net/doc/about">
            <div>
              Sobre Nubing
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="_contact" href="https://www.nubing.net/contact">
            <div>
              Contacto
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a href="#" class="_whatsapp" @click="openNew('https://api.whatsapp.com/send?phone=541154578323')">
            <i class="fab fa-whatsapp"></i> Chatea con nosotros
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewThey from "@/pages/index/NewThey";
import NewSteps from "@/pages/index/NewSteps";
import Reasons from "@/pages/index/Reasons";
import Stories from "@/pages/index/Stories";
import JoinUs from "@/pages/index/JoinUs";
import Start from "@/pages/index/Start";
import NewReasons from "@/pages/index/NewReasons";
import FAQ from "@/pages/index/FAQ";
import NewNumbers from "@/pages/index/NewNumbers";
import Finish from "@/pages/index/Finish";

export default {
  name: "Index",
  // eslint-disable-next-line vue/no-unused-components
  components: {Finish, NewNumbers, FAQ, NewReasons, Start, JoinUs, Stories, Reasons, NewSteps, NewThey},
  mounted() {
    window.ScrollOut({
      once: true
    })
  },
  data() {
    return {
      openMenu: false,
      openPlans: false,
      openSolutions: false
    }
  },
  methods: {
    openNew: function (url) {
      window.open(url, "_blank");
    },
    toggleMenu() {
      this.openMenu = !this.openMenu
      var body = document.getElementsByTagName("body")[0];
      if (this.openMenu) {
        body.classList.add("noScroll");
      } else {
        body.classList.remove("noScroll");
      }
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    togglePlans() {
      this.openPlans = !this.openPlans
    },
    toggleSolutions() {
      this.openSolutions = !this.openSolutions
    }
  }
}
</script>

<style scoped>
.index {
  overflow: hidden;
}

[data-scroll] {
  transition: 1s;
  transform: translateX(100px);
  opacity: 0;
}

[data-scroll="in"] {
  transform: translateX(0);
  /*transition-delay: 1s;*/
  opacity: 1;
}

.hamburger {
  cursor: pointer;
  margin-left: 10px;
}

.scrollable {
  overflow: auto;
}

.scrollable::-webkit-scrollbar {
  width: 5px;
  background-color: #e2e0e0;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #3c8dbc;
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #3c8dbc;
  border: 1px solid #333333;
}

.scrollable::-webkit-scrollbar-thumb:active {
  background-color: #3c8dbc;
  border: 1px solid #333333;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #313030;
  transition: .2s linear;
  opacity: 0;
  z-index: 10;
  position: fixed;
  visibility: hidden;
}

.overlay.active {
  opacity: 0.55;
  visibility: visible;
}

.menu-content {
  z-index: 11;
  width: 100%;
  max-width: 250px;
  position: fixed;
  top: 94px;
  left: 0;
  bottom: 0;
  background: #62B0E5;
  display: flex;
  flex-direction: column;
  transition: .2s linear;
  transform: translateX(-100%);
  visibility: hidden;
}

.menu-content.active {
  transform: translateX(0);
  visibility: visible;
}

.menu-item {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 15px 0 15px 25px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.menu-item svg{
  position: absolute;
  right: 15px;
  top: 18px;
}

.menu-item .fa-chevron-up,.menu-item.active .fa-chevron-down{
  display: none;
}

.menu-item.active .fa-chevron-up, .menu-item .fa-chevron-down{
  display: block;
}

.collapse{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.collapse.active{
  max-height: 100%;
  transition: max-height 0.5s ease-in;
}

.collapse .menu-item{
  background-color: #559BCB;
  border-radius: 0px;
  padding-left: 35px;
}



.menu-item a {
  color: #FFFFFF;
  text-decoration: none;
}

.main-6 > .content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.main-5 .content {
  position: relative;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.boring-link {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #F5F5F5;
  text-decoration: none;
  padding: 25px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  max-width: 1000px;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 132px;
  width: 100%;
  background: #385398;
}

.footer a svg{
  font-size: 18px;
  color:white;
  padding-right: 5px;
  padding-left: 5px;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 94px;
  background: #385398;
  z-index: 99;
}

.nav > .content {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1200px;
}

.logo-header {
  mix-blend-mode: lighten;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 169px;
}

.logo-footer {
  mix-blend-mode: lighten;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 169px;
}

.nav > .content > .login {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 200;
  font-size: 12px;
  line-height: 22px;
  text-decoration: underline;
  color: white;
  margin-right: 10px;
}

.main-2 > img {
  position: absolute;
  z-index: 1;
  height: 90%;
  width: 80%;
  bottom: 0;
  right: 0;
}

.main-2 > .content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

.main-1 > .content {
  padding-top: 240px;
  position: relative;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-3 > .story-1 > .content {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

h2 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 55px;
  color: white;
  margin: 0;
}

@media (max-width: 768px) {
  .footer-links {
    display: none;
  }

  .footer-content {
    width: auto;
    flex-direction: column;
  }
}

</style>
