<template>
  <div class="wizard">
    <div class="form scrollable">
      <img class="logo" src="./../../assets/nubing-nav-logo.png"/>
      <div class="vertical start">
        <div class="cta">
          <h1 class="title">Sobre tu tienda</h1>
          <p class="subtitle">¿En que categoría se ubica?</p>
          <div class="templates">
          <span v-for="template in templates" v-bind:key="template.id" class="templateSelector"
                :class="isSelected(template)?'selected':''" @click="select(template)">
            {{ template.name }}
          </span>
          </div>

          <div class="links">
            <router-link to="/wizard" class="back">Volver</router-link>
            <router-link to="/wizard-name" class="btn-orange">Siguiente</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="media" v-if="template">
      <Preview/>
    </div>
    <div class="media category" v-else>

    </div>
  </div>
</template>
<script>
import Preview from "@/pages/wizard/Preview";

var axios = require("axios")
export default {
  name: "Category",
  components: {Preview},
  data: function () {
    return {
      templates: null
    }
  },
  computed: {
    template: {
      get() {
        return this.$store.state.template
      },
      set(value) {
        this.$store.commit('updateTemplate', value)
      }
    }
  },
  mounted() {
    return axios.get(
        "https://www.nubing.net/public-api/public/site/templates")
        .then(r => {
          var result = r.data;
          var other = {...result[0], id: "BLANK", name: "Otro"};
          result.push(other);
          this.templates = result;
        })
        .catch(error => {
          console.log(error);
          this.templates = [{name: "Otro"}];
        });
  },
  methods: {
    select: function (_template) {
      this.template = _template;
    },
    isSelected: function (_template) {
      return this.template == _template;
    }
  }
}

</script>

