<template>
  <div class="wizard">
    <div class="form">
      <img class="logo" src="./../../assets/nubing-nav-logo.png"/>
      <div class="vertical">
        <div class="cta">
          <h1 class="title">Comencemos a armar tu tienda online</h1>
          <p class="subtitle">Contanos un poco sobre tu negocio para comenzar a armar tu tienda</p>
          <router-link to="/wizard-category" class="btn-orange">¡Comencemos!</router-link>
        </div>
      </div>
    </div>
    <div class="media start">
      <Preview/>
    </div>
  </div>
</template>
<script>
import Preview from "@/pages/wizard/Preview";

export default {
  name: 'Start',
  components: {Preview},
}
</script>

<style scoped>
.preview {
  display: none;
}
</style>
