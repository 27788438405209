<template>
  <div class="main-3 stories">
    <div v-for="(story, idx) in stories" :key="idx" class="story" :class="classes(story, idx)">
      <div class="overlay"/>
      <div class="content">
        <img class="arrow" src="../../assets/arrow-left.png" :data-scroll="idx === 0" @click="previous()"/>
        <div class="text" data-scroll>
          <h2>{{ story.title }}</h2>
          <div class="separator"/>
          <p class="description">
            {{ story.description }}
          </p>
          <div class="images">
            <img class="brand" :src="story.logo"/>
            <img class="nubing-logo" src="../../assets/nubing.png"/>
          </div>
        </div>
        <div class="person" data-scroll>
          <img class="face" :src="story.photo"/>
          <span class="name">{{ story.name }},</span>
          <span class="company">{{ story.company }}</span>
        </div>
        <img class="arrow" src="../../assets/arrow-right.png" :data-scroll="idx === 0" @click="next()"/>
      </div>
      <div class="content-mobile">
        <div class="person" data-scroll>
          <img class="face" :src="story.photo"/>
          <span class="name">{{ story.name }},</span>
          <span class="company">{{ story.company }}</span>
        </div>
        <div class="text" data-scroll>
          <h2>{{ story.title }}</h2>
          <div class="separator"/>
          <p class="description">
            {{ story.description }}
          </p>
          <div class="images-container">
            <div class="images">
              <img class="brand" v-bind:src="story.logo"/>
              <a href="https://www.nubing.net/vemmas.calzados">
                <img class="nubing-logo" src="../../assets/nubing.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Stories',
  mounted() {
    this.restartInterval()
  },
  methods: {
    classes(story, idx) {
      if ((this.index % this.stories.length) === idx) {
        return [story.className, "active"]
      } else {
        return story.className
      }
    },

    restartInterval() {
      clearInterval(this.currentInterval)
      this.currentInterval = setInterval(() => {
        window.scroll(window.scrollX, window.scrollY + 1)
        window.scroll(window.scrollX, window.scrollY -1)
        if (this.scrollVisible) {
          this.next();
        } else {
          var datascroll = document.getElementsByClassName("stories")[0].getAttribute("data-scroll");
          if (datascroll == "in") {
            this.scrollVisible = true;
          }
        }
        this.index = this.index + 1
      }, 10000)
    },
    next() {
      this.index = this.index + 1
      this.restartInterval()
      window.scroll(window.scrollX, window.scrollY + 1)
      window.scroll(window.scrollX, window.scrollY -1)
    },
    previous() {
      if (this.index > 0) {
        this.index = this.index - 1
        this.restartInterval()
      } else {
        this.index = this.stories.length - 1
        this.restartInterval()
      }
      window.scroll(window.scrollX, window.scrollY + 1)
      window.scroll(window.scrollX, window.scrollY -1)
    }
  },
  data: function () {
    return {
      currentInterval: undefined,
      index: 0,
      scrollVisible: false,
      stories: [{
        className: "eden",
        title: "\"Ahora tengo un negocio que trabaja más inteligente\"",
        description: "Ahorro tiempo y el estress de no tener el stock digitalizado. Sé lo que hay y lo que no. También aumentaron las ventas.\n" +
            "Las compras surgen de cualquier lado del país. Me puedo ir a descansar a mi casa y la gente sigue comprando. Con Nubing no estoy sola, lo simplifican...",
        logo: require("../../assets/stories/eden-logo.png"),
        photo: require("../../assets/stories/eden-photo.png"),
        name: "Mónica R.",
        company: "EDEN NATURAL"
      },
        {
          className: "entremares",
          title: "\"Central, insertarnos en el mundo digital. Hubo una gran evolución\"",
          description: "Decidimos apoyarnos en esta vitrina de e-commerce para poder llegar a esos nuevos clientes y distribuidores de distintos puntos del país que teniamos fuera de visión.\n" +
              "Migramos el modelo al mundo digital y empezamos a tener reconocimiento como marca.",
          logo: require("../../assets/stories/entre-mares-logo.png"),
          photo: require("../../assets/stories/entre-mares-photo.png"),
          name: "Marcos L.",
          company: "ENTRE MARES"
        },
        {
          className: "vemmas",
          title: "\"Hace más de 6 años que trabajamos con Nubing.\"",
          description: "El e-commerce es fundamental en nuestra empresa hoy día. Digitalizar todo comercialmente ayudó mucho. Más allá del crecimiento en ventas, se convirtió en herramienta laboral interna. Nos permitió controlar la empresa desde el celular de forma remota 24*7, sin necesidad estricta de estar en ella.",
          logo: require("../../assets/stories/vemmas-logo.png"),
          photo: require("../../assets/stories/vemmas-photo.png"),
          name: "Noelia B.",
          company: "VEMMAS CALZADOS"
        }]
    }
  }
}
</script>
<style scoped>
.arrow[data-scroll] {
  transition: 1s;
  transition-delay: 1s;
  opacity: 0;
}

.arrow[data-scroll="in"] {
  opacity: 1;
}

.text[data-scroll] {
  transition: 1s;
  transform: translateY(-100%);
  opacity: 0;
}

.text[data-scroll="in"] {
  transform: translateY(0);
  opacity: 1;
}

.person[data-scroll] {
  transition: 1s;
  transform: translateY(100%);
  opacity: 0;
}

.person[data-scroll="in"] {
  transform: translateY(0);
  opacity: 1;
}

.images {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 200px;
}

.content-mobile .images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.face {
  max-width: 245px;
}

.content-mobile .face {
  width: 200px;
  padding-top: 80px;
}

.description {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  min-height: 210px;
}

@media (min-width: 1200px) {
  .description {
    font-size: 28px;
    line-height: 34px;
  }
}

.main-3 {
  width: 100%;
  /*height: 100vh;*/
}


.story {
  height: 100%;
  position: relative;
  display: none;
  align-items: center;
  padding: 100px 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.story.active {
  display: flex;
}

.story.eden {
  background-image: url("../../assets/stories/mobile/eden-background.png");
}

.story.entremares {
  background-image: url("../../assets/stories/mobile/entre-mares-background.png");
}

.story.vemmas {
  background-image: url("../../assets/stories/mobile/vemmas-background.png");
}


@media (min-width: 1200px) {
  .story.eden {
    background-image: url("../../assets/stories/eden-background.png");
  }

  .story.entremares {
    background-image: url("../../assets/stories/entre-mares-background.png");
  }

  .story.vemmas {
    background-image: url("../../assets/stories/vemmas-background.png");
  }

}

.main-3 > .story > .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #394f87;
  opacity: 0.8;
}

.main-3 > .story.entremares > .overlay {
  background-color: #33D1FF;
}

.main-3 > .story.vemmas > .overlay {
  background-color: #D88B62;
}

.content {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
}

.content-mobile {
  display: none;
}


@media (max-width: 1200px) {
  .content {
    display: none;
  }

  .content-mobile {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    margin: 50px;
  }

  .story {
    padding: 0;
  }

  .brand {
    max-width: 150px;
  }
}

.main-3 > .story > .content > .text {
  display: flex;
  flex-direction: column;
  padding-right: 150px;
}

.brand {
  place-self: flex-start;
  margin-top: 36px;
}

img.brand {
  width: 100%;
  max-width: 250px;
}

.content-mobile img.brand {
  width: auto;
  max-width: 220px;
}

.story.vemmas .content-mobile img.brand {
  width: 150px;
}

.content-mobile {
  padding-bottom: 30px;
}

.nubing-logo {
  place-self: flex-start;
  margin-top: 32px;
}

h2 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;
  margin: 0;
  color: #FFFFFF;
  min-height: 80px;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 42px;
    line-height: 55px;
  }
}

.separator {
  width: 100%;
  height: 0;
  border: 1px dashed white;
  margin-top: 31px;
}

.person {
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1200px) {
  .person {
    margin-right: unset;
  }
}

.person > .name {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: white;
  opacity: 0.9;
}

.person > .company {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: white;
  opacity: 0.9;
  text-transform: uppercase;
}


.content-mobile .name {
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
}

.content-mobile .company {
  font-size: 18px;
  line-height: 17px;
  margin-top: 5px;
}

.arrow {
  margin: 40px;
  cursor: pointer;
}

.text {
  margin-left: 50px;
}

.content-mobile .text h2 {
  font-size: 20px;
  line-height: 27px;
  text-align: left;
}

.content-mobile .text p {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}
</style>
