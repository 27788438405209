<template>
  <div class="wizard">
    <div class="form">
      <img class="logo" src="./../../assets/nubing-nav-logo.png"/>
      <div class="mobile-preview">
        <Preview/>
      </div>
      <div class="vertical">
        <div class="cta">
          <div v-if="template">
            <h1 class="title">¡Esta página puede ser tuya!</h1>
          </div>
          <p class="subtitle">Continuá creando tu tienda online</p>
          <div class="links">
            <a v-bind:href="registerLink()" class="btn-orange">Registrate</a>
          </div>
        </div>
      </div>
    </div>
    <div class="media" v-if="template">
      <Preview/>
    </div>
    <div class="media category" v-else>

    </div>
  </div>

</template>
<script>
import Preview from "@/pages/wizard/Preview";

export default {
  name: 'Start',
  components: {Preview},
  computed: {
    template: {
      get() {
        return this.$store.state.template
      }
    }
  },
  methods: {
    registerLink: function () {
      let st = this.$store.state;
      return "https://www.nubing.net/register?wizard=" + btoa(JSON.stringify(st));
    }
  }
}
</script>

<style>

.mobile-preview {
  width: 100%;
  padding: 30px;
  position: relative;
}

.mobile-preview .preview .iframe.mobile {
  overflow: hidden;
  bottom: 50px;
  left: 5px;
}

.form {
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .mobile-preview {
    display: none;
  }

  .form {
    overflow-x: initial;
  }
}
</style>
