<template>
  <div class="preview">
      <div class="iframe desktop">
        <iframe :src="iframeUrl()" scrolling="no"/>
      </div>
      <div class="iframe mobile">
        <iframe :src="iframeUrl()" scrolling="no"/>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Preview',
  methods: {
    iframeUrl() {
      var state=this.$store.state;
      return `https://uyyzfxvzj6.execute-api.us-east-1.amazonaws.com/dev/users/${state.template.owner}-${state.template.catalog}/index?name=${state.storeName}&headerColor=${state.storeColor}&fontColor=000000&footerMainColor=${state.storeColor}`
    }
  }
}
</script>
