<template>
  <div class="main-2">
    <div class="gradient"></div>
    <div class="content">
      <img class="ellipsis" src="./../../assets/ellipsis.png">
      <div class="real-content">
        <div class="images">
          <img class="laptop" src="./../../assets/compu.png">
          <img class="message" data-scroll src="./../../assets/Mensajito.png">
        </div>
        <div class="text">
          <h2 class="title">
            ¿Qué es nubing?
          </h2>
          <p class="description">
            <b>nubing.net</b> es plataforma de comercio electrónico que te permite tener, en un mismo lugar, desde la
            administración de tu negocio online con dominio propio hasta

            la posibilidad de conectarte libremente con otros usuarios, vender y comprar.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JoinUs'
}
</script>
<style scoped>
@media (min-width: 922px) {
  [data-scroll] {
    transition: 1s;
    transform: translateX(100px);
    opacity: 0;
  }

  [data-scroll="in"] {
    transform: translateX(0);
    /*transition-delay: 1s;*/
    opacity: 1;
  }
}

.message {
  position: absolute;
  top: -12%;
  right: 53%;
  z-index: 4;
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.laptop {
  position: absolute;
  right: 40%;
  top: 10%;
  z-index: 3;
  max-height: 90%;
}

@media (max-width: 922px) {
  .images {
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .laptop {
    top: 0;
    left: 0;
    width: 100%;
    max-width: unset;
  }

  .message {
    top: 0;
    right: 0;
    width: 40%;
  }
}

.title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #F5F5F5;
}

@media (min-width: 768px) {
  .title {
    font-size: 12px;
    line-height: 15px;
  }
}

.description {
  margin-left: 40px;
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .title {
    font-size: 42px;
    line-height: 55px;
  }
}

.text {
  position: absolute;
  left: 40%;
  top: 20%;
  right: 10%;
  z-index: 2;
}

@media (max-width: 922px) {
  .text {
    left: unset;
    top: unset;
    right: unset;
    position: relative;
    margin-top: -50px;
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background: none;
}

.main-2 {
  position: relative;
  width: 100%;
  height: 100vh;
  color: white;
  padding-top: 250px;
  background: #385398;
}

.ellipsis {
  object-fit: fill;
  position: absolute;
  width: 100%;
  height: 80%;
  top: 10%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: none;
}

@media (min-width: 1200px) {
  .main-2 {
    background: white;
  }

  .gradient {
    background: linear-gradient(180deg, rgba(99, 176, 229, 0) 0%, #63B0E5 75.48%);
  }

  .ellipsis {
    display: block;
  }
}

@media (max-width: 922px) {
  .main-2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 922px) {
  .main-2 {
    height: unset;
  }
}

.content {
  padding-top: 240px;
  position: relative;
  width: 80%;
  max-width: 1000px;
  height: 50%;
  margin: 0 auto;
}

@media (max-width: 922px) {
  .content {
    padding-top: 0;
    padding-bottom: 80px;
  }
}

</style>
