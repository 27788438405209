<template>
  <div class="main">
    <div class="content">
      <img class="background" src="./../../assets/finish-background.png" data-scroll>
      <div class="message">
        <div class="emoji" data-scroll>
          <img src="./../../assets/finish-emoji.png">
          <div class="ok" data-scroll>
            <svg width="70" height="35" class="ok-rope" viewBox="0 0 70 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00024 34C4.25811 22.4469 10.7964 15.4728 24.2198 9.23286C38.0999 2.78054 53.2806 -1.12826 69.0002 2.22994" stroke="url(#paint0_linear)" stroke-width="1.47376" stroke-dasharray="1.47 2.95"/>
              <defs>
                <linearGradient id="paint0_linear" x1="-22.2193" y1="40.8595" x2="59.1807" y2="-7.101" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="0.621868" stop-color="white" stop-opacity="0.946801"/>
                  <stop offset="0.91892" stop-color="white" stop-opacity="0"/>
                </linearGradient>
              </defs>
            </svg>
            <svg width="27" class="ok-icon" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.1191 15.2664C26.6861 14.4852 27 13.528 27 12.5321C27 10.9521 26.1529 9.45652 24.7894 8.62252C24.4384 8.40785 24.0387 8.29486 23.6318 8.29526H15.5385L15.741 3.97042C15.7883 2.92528 15.4339 1.93293 14.7454 1.17634C14.4075 0.803431 13.9998 0.506727 13.5476 0.304569C13.0954 0.102411 12.6083 -0.000906143 12.1163 0.00100253C10.3613 0.00100253 8.80875 1.23265 8.343 2.99566L5.44387 13.9397H5.43375V29.001H21.3739C21.6844 29.001 21.9881 28.9376 22.2683 28.811C23.8748 28.0966 24.9109 26.4603 24.9109 24.6445C24.9109 24.2011 24.8501 23.7647 24.7286 23.3424C25.2956 22.5612 25.6095 21.6041 25.6095 20.6082C25.6095 20.1648 25.5488 19.7284 25.4273 19.3062C25.9943 18.525 26.3081 17.5678 26.3081 16.5719C26.3014 16.1285 26.2406 15.6886 26.1191 15.2664ZM0 15.0658V27.8749C0 28.4978 0.482625 29.001 1.08 29.001H3.27375V13.9397H1.08C0.482625 13.9397 0 14.4429 0 15.0658Z" fill="#FBBE47"/>
            </svg>
          </div>
          <div class="msj" data-scroll>
          <svg width="68" height="141" class="message-rope" viewBox="0 0 68 141"  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0532 140.027C4.41526 115.303 -7.69967 70.8906 13.8362 38.9624C27.7834 18.2848 47.1727 5.82322 67.0815 0.681317" stroke="url(#paint0_linear)" stroke-width="1.47376" stroke-dasharray="1.47 2.95"/>
            <defs>
              <linearGradient id="paint0_linear" x1="29.3356" y1="89.9772" x2="87.0691" y2="-5.42178" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="0.621868" stop-color="white" stop-opacity="0.946801"/>
                <stop offset="0.91892" stop-color="white" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>
          <svg width="31" height="32"  class="message-icon" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.22725 31.0327C8.8281 31.357 8.32939 31.4956 7.83845 31.4187C7.34752 31.3418 6.9036 31.0555 6.60225 30.6214C6.36068 30.2706 6.22966 29.8425 6.22913 29.4021V24.9132H5.10413C2.41163 24.9132 0.229126 22.5386 0.229126 19.6101V5.74258C0.229126 2.81408 2.41163 0.439453 5.10413 0.439453H25.3541C28.0466 0.439453 30.2291 2.81408 30.2291 5.74258V19.6101C30.2291 22.5407 28.0466 24.9132 25.3541 24.9132H16.7479L9.22725 31.0327Z" fill="#FBBE47"/>
          </svg>
          </div>


          <div class="heart" data-scroll>
            <svg width="19" height="78" class="heart-rope" viewBox="0 0 19 78" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.82475 77.4074C0.2639 66.4735 1.10073 50.9002 4.82478 34C7.75046 20.7229 10.9588 12.7231 18 1.00007" stroke="url(#paint0_linear)" stroke-width="1.47376" stroke-dasharray="1.47 2.95"/>
              <defs>
                <linearGradient id="paint0_linear" x1="41.9142" y1="122.829" x2="24.7082" y2="12.5636" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="0.621868" stop-color="white" stop-opacity="0.946801"/>
                  <stop offset="0.91892" stop-color="white" stop-opacity="0"/>
                </linearGradient>
              </defs>
            </svg>
            <svg width="36" class="heart-icon" height="31" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.7417 0.0820312C22.4007 0.0820312 19.4576 1.83203 17.7871 4.58203C16.1167 1.83203 13.1735 0.0820312 9.83256 0.0820312C4.58256 0.0820312 0.287109 4.58203 0.287109 10.082C0.287109 19.9987 17.7871 30.082 17.7871 30.082C17.7871 30.082 35.2871 20.082 35.2871 10.082C35.2871 4.58203 30.9917 0.0820312 25.7417 0.0820312Z" fill="#FBBE47"/>
            </svg>
          </div>

          <div class="connection" data-scroll>
            <svg width="52" height="105" class="connection-rope" viewBox="0 0 52 105" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M49.8323 104.563C52.7324 81.6868 50.8236 67.9368 41.9405 47.7147C32.2384 25.6282 22.1628 14.1189 1.04506 1.00017" stroke="url(#paint0_linear)" stroke-width="1.47376" stroke-dasharray="1.47 2.95"/>
              <defs>
                <linearGradient id="paint0_linear" x1="78.5535" y1="136.401" x2="-42.1768" y2="25.1729" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="0.621868" stop-color="white" stop-opacity="0.946801"/>
                  <stop offset="0.91892" stop-color="white" stop-opacity="0"/>
                </linearGradient>
              </defs>
            </svg>
            <svg width="44" height="29" class="connection-icon" viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.25195 7.56614C6.25195 11.571 9.4877 14.5922 13.777 14.5922C18.0662 14.5922 21.302 11.571 21.302 7.56614C21.302 3.56126 18.0662 0.540039 13.777 0.540039C9.4877 0.540039 6.25195 3.56126 6.25195 7.56614ZM37.427 6.45802H33.127V12.4804H26.677V16.4953H33.127V22.5177H37.427V16.4953H43.877V12.4804H37.427V6.45802ZM5.17695 28.54H26.677V26.5326C26.677 20.998 21.8545 16.4953 15.927 16.4953H11.627C5.6994 16.4953 0.876953 20.998 0.876953 26.5326V28.54H5.17695Z" fill="#FBBE47"/>
            </svg>
          </div>
        </div>
        <div class="text" data-scroll>
          <h1 class="title">Te acompañamos en la<br>transformación de tu<br>negocio al mundo online</h1>
          <h3 class="subtitle">Ofrecemos soluciones y planes que se adaptan a tu marca.<br>Comenzá ahora a armar tu
            tienda online</h3>
          <router-link to="/wizard" class="btn-orange" data-scroll>¡Comencemos!</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Finish'
}
</script>
<style scoped>
.background[data-scroll] {
  transition: 1s;
  opacity: 0;
}

.background[data-scroll="in"] {
  opacity: 1;
}

.text[data-scroll] {
  transition: 1s;
  transition-delay: 1s;
  opacity: 0;
}

.text[data-scroll="in"] {
  opacity: 1;
}

.emoji[data-scroll] {
  transition: 1s;
  opacity: 0;
}

.emoji[data-scroll="in"] {
  opacity: 1;
}

.emoji {
  margin-top: 60px;
  margin-left: -50px;
  position: relative;
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.emoji .ok-rope{
  position: absolute;
  left: 120px;
  bottom: 30px;
}

.emoji .ok-icon{
  position: absolute;
  left: 110px;
  bottom: 5px;
}

.emoji .message-rope{
  position: absolute;
  left: 190px;
  bottom: -60px;
}

.emoji .heart,.emoji .ok, .emoji .msj,.emoji .connection{
  height: 1px;
  width: 1px;
  display: none;
}

@media(min-width: 922px) {
  .emoji .heart,.emoji .ok, .emoji .msj,.emoji .connection {
    display: block;
  }
}

.emoji .ok[data-scroll] {
  transition: 3s;
  transition-delay: 2s;
  transform: translateY(-200px);
  opacity: 0;
}

.emoji .ok[data-scroll="in"] {
  transform: translateY(0px);
  opacity: 1;
}

.emoji .msj[data-scroll] {
  transition: 3s;
  transition-delay: 3s;
  transform: translateY(-200px);
  opacity: 0;
}

.emoji .msj[data-scroll="in"] {
  transform: translateY(0px);
  opacity: 1;
}

.emoji .heart[data-scroll] {
  transition: 3s;
  transition-delay: 4s;
  transform: translateY(-200px);
  opacity: 0;
}

.emoji .heart[data-scroll="in"] {
  transform: translateY(0px);
  opacity: 1;
}

.emoji .connection[data-scroll] {
  transition: 3s;
  transition-delay: 5s;
  transform: translateY(-200px);
  opacity: 0;
}

.emoji .connection[data-scroll="in"] {
  transform: translateY(0px);
  opacity: 1;
}

.emoji .message-icon{
  position: absolute;
  left: 180px;
  bottom: -70px;
}


.emoji .heart-rope{
  position: absolute;
  left: 235px;
  bottom: -50px;
}

.emoji .heart-icon{
  position: absolute;
  left: 227px;
  bottom: -80px;
}
.emoji .connection-rope{
  position: absolute;
  left: 240px;
  bottom: 20px;
}

.emoji .connection-icon{
  position: absolute;
  left: 270px;
  bottom: -10px;
}

.emoji img{
  width: 100%;
}

.background {
  position: absolute;
  z-index: 1;
  height: 90%;
  width: 100%;
  display: none;
}


@media (min-width: 922px) {
  .background{
    display: block;
  }
}

.message {
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media(min-width: 922px) {
  .message{
    align-items: flex-start;
  }
}


.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
}

@media (max-width: 922px) {

  .message {
    flex-direction: column;
  }

  .emoji {
    margin: -50px 0 0;
    width: 100px;
  }

  .text {
    margin: 0 20px;
    align-items: center;
  }

}

.title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #F5F5F5;
  margin-bottom: 0;
  text-align: center;
}

.subtitle {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.9;
  margin-top: 19px;
  margin-bottom: 0;
}

.btn-orange {
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  padding: 20px 30px;
  background: #F8671B;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  color: white;
  margin-bottom: 120px;
  margin-top: 26px;
}

.content {
  position: relative;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(min-width: 922px){
  .content{
    align-items: flex-start;
  }
}

.main{
  background: #385398;
  position: relative;
  width: 100%;
  color: white;
  padding: 90px 0;
}


@media (min-width: 922px) {
  .main {
    background: linear-gradient(180deg, rgba(99, 176, 229, 0) 0%, #63B0E5 75.48%);
  }
  .content {
    width: 95%;
    padding-bottom: 40px;
  }

  .title {
    margin-top: 150px;
    margin-top: 80px;
    font-size: 42px;
    line-height: 55px;
    text-align: left;
  }

  .subtitle{
    font-size: 18px;
    line-height: 22px;
  }
}


</style>
