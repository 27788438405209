<template>
  <div class="main-2">
    <div class="content">
      <div class="card users" data-scroll>
        <img class="many" src="./../../assets/many.png" />
        <div class="numbers">
            <span class="number">
              +200.000
            </span>
        </div>
        <div class="separator"></div>
        <span class="description">usuarios registrados</span>
      </div>
      <div class="card" data-scroll>
        <img class="fewer" src="./../../assets/fewer.png" />
        <div class="numbers">
            <span class="number">
              +50
            </span>
        </div>
        <div class="separator"></div>
        <span class="description">nuevas cuentas diarias</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Numbers'
}
</script>
<style scoped>
.card[data-scroll] {
  transition: 1s;
  opacity: 0;
}

.card[data-scroll="in"] {
  opacity: 1;
}

.many {
  max-width: 404px;
}
.fewer {
  max-width: 180px;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 0 100px;
  align-items: center;
}

.users {
  align-items: center;
}

@media(min-width: 768px){
  .users{
    align-items: flex-end;
  }
}

.description {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #535353;
  margin-top: 10px;
}

.separator {
  width: 100%;
  height: 0;
  border: 1px dashed #71B4E2;
}

.numbers {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.number {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  color: #71B4E2;
}

@media(min-width: 768px){
  .number{
    font-size: 80px;
    line-height: 95px;
  }
}
.main-2 {
  position: relative;
  width: 100%;
  /*height: 50vh;*/
  background: white;
  color: white;
}

.content {
  position: relative;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 140px 0 90px;
}

@media (max-width: 1200px) {
  .content {
    padding-top: 0;
    flex-direction: column;
  }
  .card {
    width: 100%;
    margin: 0;
  }

  .many {
    max-width: 90%;
  }
  .fewer {
    margin-top: 90px;
    max-width: 50%;
  }
}

</style>
