<template>
  <div class="main">
    <div class="content">
      <img class="question-face header" src="./../../assets/question-face.png" data-scroll>
      <h2 class="blue-title header" data-scroll>Preguntas frecuentes</h2>
      <div class="questions-content">
        <span class="arrow previous" @click="previousButton()" v-if="hasPrevious()">
        <i class="fas fa-chevron-left"></i>
        </span>
        <transition-group :name="transitionName" tag="div" class="questions" data-scroll>
          <div v-for="(faq, idx) in visible" :key="faq.id" class="question" :class="classes(faq, idx)"
               :data-scroll="faq.dataScroll">
            <p class="title">
              {{ faq.question }}
            </p>
            <p class="description">
              {{ faq.answer }}
            </p>
          </div>
        </transition-group>
        <span class="arrow next" @click="nextButton()" v-if="hasNext()">
        <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FAQ',
  data: function () {
    var _faqs = [
      {
        id: 1,
        question: "Puedo tener un catálogo de productos online?",
        answer: "Si. Con nubing.net podés crear cuenta sin cargo y publicar tus productos de forma ordenada y categorizada en un catálogo online asociado al nombre de tu negocio",
        dataScroll: ""
      },
      {
        id: 2,
        question: "Puedo crear mi tienda online por mi cuenta?",
        answer: "Si. Contratando el Plan Tienda de Barrio podrás acceder al armado de tu sitio web para comercio electrónico sin depender de un profesional",
        dataScroll: ""
      },
      {
        id: 3,
        question: "Si quisiera que me ayuden con el armado de mi tienda, Nubing lo hace?",
        answer: "Si. Nuestro equipo de diseñadores, desarrolladores y consultores en e-commerce pueden asistirte desde el armado de una web básica hasta un desarrollo a medida. Esto se presupuesta en función del requerimiento.",
        dataScroll: ""
      }, {
        id: 4,
        question: "Necesito facturar electrónicamente mis ventas online. Puedo hacerlo con nubing.net?",
        answer: "Si. Con tu cuenta en nubing.net podras facturar electrónicamente de forma directa. En dos clicks transformas tus ordenes de compra en comprobantes fiscales que luego se envían en adjunto por mail a tu cliente.",
        dataScroll: ""

      }, {
        id: 5,
        question: "Tengo local a la calle y recibo pedidos por aplicaciones de delivery. Puedo recibir estos pedidos desde mi cuenta en nubing?",
        answer: "Claro. Contratando el Plan Tienda Global podes tener tus pedidos integrados para gestionarlos desde un solo lugar, ahorrando tiempo y evitando errores.\n" +
            "Al mismo tiempo que podes crear ticket fiscal online en segundos al estar también integrado a factura electrónica"
      }, {
        id: 6,
        question: "Actualmente vendo solo por marketplace (Mercado Libre, Facebook, Dafiti, Pedidos Ya, entre otros) y quiero desarrollar mi tienda online en Nubing. Puedo hacerlo sin dejar de vender en el marketplace?",
        answer: "Claro. Podes tener tu tienda online en Nubing y seguir vendiendo en otros maketplaces."
      }, {
        id: 7,
        question: "Puedo recibir mis ventas de marketplace en mi bandeja de ventas de Nubing?",
        answer: "Si. Podes integrar y recibir tus pedidos de marketplace en la misma bandeja de pedidos de tu tienda en Nubing, ahorrando tiempo y aprovenchando funcionalidades que mejoran la gestión.\n" +
            "Al mismo tiempo que podes crear ticket fiscal online en segundos al estar también integrado a factura electrónica"
      }, {
        id: 8,
        question: "Necesito sistema de gestión que me permita tener las ventas online integradas para una adminsitración completa. En nubing.net lo puedo tener?",
        answer: "Si. Podes activar aplicación para gestión de compras además de ventas, gestión de proveedores, clientes, momivientos de cuenta, pagos, cobros, etc."
      }, {
        id: 9,
        question: "No tenemos tiempo para publicar, hacer publicidad o responder a consultas en redes sociales. En Nubing me brindan ese servicio?",
        answer: "Si. Según el Plan de Comunicación que contrates podrás contar con un equipo de comunicadores que trabajarán para posicionar marca y productos acorde a tu presupuesto."
      }, {
        id: 10,
        question: "Necesitamos generar contenido para la comunicación y venta de nuestros productos. En Nubing hay equipo para poder hacerlo?",
        answer: "Si. En nubing buscamos el equilibrio entre comunicación y diseño, ofreciendo un Plan Posicionamiento basado en el desarrollo de contenido publicitario innovador."
      }, {
        id: 11,
        question: "Que dominio web tendrá mi tienda?",
        answer: "Cuando crees tu tienda, la misma va a tener un dominio gratuito que incluye el servicio de nubing.net y será nubing.net/mitienda Podrás utilizarlo sin problemas o configurar tu dominio propio directamente desde tu configuración de cuenta."
      }, {
        id: 12,
        question: "Puedo administrar la tienda desde mi celular?",
        answer: "Si claro. nubing.net esta adaptado para que sea accesible desde cualquier dispositivo"
      }, {
        id: 13,
        question: "No se sobre diseño ni programación, puedo crear mi tienda online por mis propios medios de forma sencilla?",
        answer: "Claro. nubing.net esta oriendato para que cualquier usuario pueda crear, diseñar y adminsitrar su negocio online de manera fácil e intuitiva. A su vez, si tuvieras conocimientos de CSS y HTML también podrías usar ambas herramientas para personalizar tu web."
      }, {
        id: 14,
        question: "Una vez creada mi tienda online, como hago para darle exposición?",
        answer: "Para que la gente visite tu negocio online te daremos las soluciones y tips necesarios para generar interés y concurrencia."
      }, {
        id: 15,
        question: "Mi tienda tiene un límite de almacenamiento?",
        answer: "No. No hay límite. Podes contar con productos y ventas ilimitadas."
      }, {
        id: 16,
        question: "Recibo factura por el pago mensual del servicio de Nubing. En que moneda se abona y como se paga?",
        answer: "Si claro. Realizamos facturas tipo A o B según tu situación fiscal. El precio de nuestros servicios es en pesos y se puede abonar con tarjeta de crédito, débito, tarjeta prepaga, efectivo o transferencia."
      }, {
        id: 17,
        question: "Puedo cancelar el servicio en cualquier momento?",
        answer: "Si. Podes cancelar el servicio cuando desees."
      }];
    return {
      transitionName: undefined,
      currentInterval: undefined,
      index: 0,
      faqs: _faqs,
      width: window.innerWidth,
      visible: _faqs.slice(0, this.slidesVisible()),
      scrollVisible: false,
    }
  },
  watch: {
    width: function (val, oldValue) {
      if (val != oldValue) {
        this.width = val;
        this.initializeSlides();
      }
    }
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  mounted() {
    if (this.transitionName == undefined) {
      this.transitionName = "questionList";
    }
    this.initializeSlides();
    this.restartInterval();
  },
  methods: {
    initializeSlides(){
      this.visible = this.faqs.slice(0, this.slidesVisible())
      this.index = 0
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    restartInterval() {
      clearInterval(this.currentInterval)
      this.currentInterval = setInterval(() => {
        if (this.scrollVisible) {
          this.next();
        } else {
          var datascroll = this.$el.getElementsByClassName("questions")[0].getAttribute("data-scroll");
          if (datascroll == "in") {
            this.scrollVisible = true;
          }
        }
      }, 5000)
    },
    hasNext() {
      return this.nextIndex() < this.faqs.length;
    },
    nextIndex() {
      return this.index + this.slidesVisible();
    },
    next() {
      if (this.hasNext()) {
        let shift = this.visible.shift();
        shift.dataScroll = "out";
        this.visible.push(this.faqs[this.nextIndex()]);
        this.index = this.index + 1
      }
    },
    nextButton() {
      clearInterval(this.currentInterval)
      this.next();
    },
    hasPrevious() {
      return this.index > 0;
    },
    previousIndex() {
      return this.index - 1;
    },
    previous() {
      if (this.hasPrevious()) {
        this.visible.pop();
        let faq = this.faqs[this.previousIndex()];
        faq.dataScroll = "in"
        this.visible.unshift(faq)
        this.index = this.index - 1
      }
    },
    previousButton() {
      clearInterval(this.currentInterval)
      this.previous();
    },

    classes(faq, idx) {
      let result = []
      if (this.index === 0) {
        if (idx % 2 === 0) {
          result.push("up");
        } else {
          result.push("down");
        }
      }
      return result;
    },

    showNext() {
      return this.index + this.slidesVisible() == this.faqs.length;
    },

    showPrevious() {
      return this.index === 0;
    },

    slidesVisible() {
      if (this.width <= 768) {
        return 1;
      }
      if (this.width <= 1200) {
        return 2;
      }
      return 3;
    }
  }

}
</script>
<style scoped>


@media (min-width: 768px) {

  .header[data-scroll] {
    transition: 1s;
    transform: translateY(100px);
    opacity: 0;
  }

  .header[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }

  .up[data-scroll] {
    transition: 1s;
    transition-delay: 1s;
    transform: translateY(100px);
    opacity: 0;
  }

  .up[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }

  .down[data-scroll] {
    transition: 1s;
    transition-delay: 1s;
    transform: translateY(-100px);
    opacity: 0;
  }

  .down[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }

}

.description {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
  color: #385398;
}

.question {
  width: 300px;
  padding: 10px;
}

.question-face{
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.questionList-enter-active, .questionList-leave-active {
  transition: all 1.5s;
}

.questionList-enter {
  opacity: 0;
  transform: translateY(-100px);
  margin-left: -320px;
}

.questionList-leave-to {
  opacity: 0;
  /*width: 0px;*/
  /*height: 0px;*/
  transform: translateY(-100px);
  margin-left: -320px;
}


@media (min-width: 768px) {
  .description {
    font-size: 18px;
    line-height: 22px;
  }
}

.title {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 500;

  font-size: 14px;
  line-height: 19px;
  color: rgba(51, 70, 119, 0.9);
}

@media (width: 768px) {
  .title {
    font-size: 18px;
    line-height: 22px;
  }
}


@media (max-width: 768px) {
  /*.question {*/
  /*  display: none;*/
  /*}*/
  /*.question:first-child {*/
  /*  display: unset;*/
  /*}*/
}

.questions {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.questions-content {
  position: relative;
}

.questions-content .arrow {
  position: absolute;
  top: 80px;
  font-size: 20px;
  color: #3c8dbc;
  cursor: pointer;
  padding: 5px;
  background-color: white;
  opacity: 0.5;
}

.questions-content .arrow.previous {
  left: -20px;
}

.questions-content .arrow.next {
  right: -20px;
}

.blue-title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #385398;
}

@media (min-width: 768px) {
  .blue-title {
    font-size: 42px;
    line-height: 55px;
  }
}

.content {
  position: relative;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 100px;
  /*height: 100%;*/
}

.main {
  position: relative;
  width: 100%;
  padding-top: 100px;
  padding-top: 100px;
  /*height: 100vh;*/
}

</style>
