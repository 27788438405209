import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index/Index'
import WizardStart from '../pages/wizard/Start'
import WizardName from '../pages/wizard/Name'
import WizardCategory from '../pages/wizard/Category'
import WizardLast from "../pages/wizard/Last"
import '../assets/css/wizard.css'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/wizard',
        name: 'Wizard',
        component: WizardStart
    },
    {
        path: '/wizard-name',
        name: 'Wizard Name',
        component: WizardName
    },
    {
        path: '/wizard-category',
        name: 'Wizard Category',
        component: WizardCategory
    },
    {
        path: '/wizard-last',
        name: 'Wizard Last',
        component: WizardLast
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
