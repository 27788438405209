<template>
  <div class="wizard">
    <div class="form scrollable">
      <img class="logo" src="./../../assets/nubing-nav-logo.png"/>
      <div class="vertical start">
        <div class="cta">
          <h1 class="title">Sobre tu tienda</h1>
          <p class="subtitle">Comencemos por algunas simples preguntas</p>
          <label class="name">¿Cuál es el nombre de tu tienda?</label>
          <input type="text" id="input-name" placeholder="Nombre de tu tienda..." v-model="name"/>
          <label class="colors">¿Con que color la relacionas más?</label>
          <div class="colors-container">
            <div class="color-container">
              <label for="color1" class="color"><img src="./../../assets/color-white.png"/> </label>
              <input id="color1" type="radio" name="color" value="FFFFFF" v-model="color">
            </div>
            <div class="color-container">
              <label for="color2" class="color"><img src="./../../assets/color-blue.png"/> </label>
              <input id="color2" type="radio" name="color" value="51A5F2" v-model="color">
            </div>
            <div class="color-container">
              <label for="color3" class="color"><img src="./../../assets/color-turquoise.png"/> </label>
              <input id="color3" type="radio" name="color" value="65D9D9" v-model="color">
            </div>
            <div class="color-container">
              <label for="color4" class="color"><img src="./../../assets/color-lime.png"/> </label>
              <input id="color4" type="radio" name="color" value="A6DF6C" v-model="color">
            </div>
            <div class="color-container">
              <label for="color5" class="color"><img src="./../../assets/color-rose.png"/> </label>
              <input id="color5" type="radio" name="color" value="F49E9C" v-model="color">
            </div>
          </div>
          <div class="links">
            <router-link to="/wizard-category" class="back">Volver</router-link>
            <router-link to="/wizard-last" class="btn-orange">Siguiente</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="media" v-if="template">
      <Preview/>
    </div>
    <div class="media category" v-else>

    </div>
  </div>
</template>
<script>
import Preview from "@/pages/wizard/Preview";

export default {
  name: "Name",
  components: {Preview},
  computed: {
    name: {
      get() {
        return this.$store.state.storeName
      },
      set(value) {
        this.$store.commit('updateName', value)
      }
    },
    template: {
      get() {
        return this.$store.state.template
      }
    },
    color: {
      get() {
        return this.$store.state.storeColor
      },
      set(value) {
        this.$store.commit('updateColor', value)
      }
    }
  }
}
</script>
