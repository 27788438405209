<template>
  <div class="main-2">
    <div class="gradient"></div>
    <div class="content">
      <div class="text">
        <h2 class="title" data-scroll>
          Ellos ya están con nosotros
        </h2>
        <div class="brands">
          <div class="card anca" data-scroll>
            <span class="brand anca">ANCA&CO</span>
            <div class="see-more">
              <a href="https://www.nubing.net/ancayco">
                <img src="../../assets/nubing-domain-logo.png"/>
              </a>
              <a href="#" @click="openNew('https://www.ancayco.com.ar')">
                <img src="../../assets/own-domain-logo.png"/>
              </a>
            </div>
          </div>
          <div class="card vanshe" data-scroll>
            <span class="brand vanshe">Vanshelato</span>
            <div class="see-more">
              <a href="https://www.nubing.net/vanshelato">
                <img src="../../assets/nubing-domain-logo.png"/>
              </a>
              <a href="#" @click="openNew('https://www.vanshelato.com.ar')">
                <img src="../../assets/own-domain-logo.png"/>
              </a>
            </div>

          </div>
          <div class="card acai" data-scroll>
            <span class="brand acai">ACAÍ BRASIL</span>
            <div class="see-more">
              <a href="https://www.nubing.net/acaibrasilarg">
                <img src="../../assets/nubing-domain-logo.png"/>
              </a>
              <a href="#" @click="openNew('https://www.acaibrasilarg.com.ar')">
                <img src="../../assets/own-domain-logo.png"/>
              </a>
            </div>
          </div>

          <div class="card vemmas" data-scroll>
            <span class="brand vemmas">VEMMAS</span>
            <div class="see-more">
              <a href="https://www.nubing.net/vemmas.calzados">
                <img src="../../assets/nubing-domain-logo.png"/>
              </a>
              <a href="#" @click="openNew('https://www.calzadosvemmas.com')">
                <img src="../../assets/own-domain-logo.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'They',
  methods: {
    openNew: function (url) {
      window.open(url, "_blank");
    }
  }
}
</script>
<style scoped>

.see-more {
  text-align: center;
}

.see-more img {
  width: 50px;
}

.card {
  display: flex;
  flex-direction: column;
}


.title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #385398;
  font-size: 20px;
  line-height: 27px;
}

@media (min-width: 768px) {

  .title {
    font-size: 42px;
    line-height: 55px;
  }

  .title[data-scroll] {
    transition: 1s;
    opacity: 0;
  }

  .title[data-scroll="in"] {
    opacity: 1;
  }

  .anca[data-scroll] {
    transition: 1s;
    transform: translateX(100px);
    opacity: 0;
  }

  .anca[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .vanshe[data-scroll] {
    transition: 1s;
    transition-delay: .25s;
    transform: translateX(100px);
    opacity: 0;
  }

  .vanshe[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .acai[data-scroll] {
    transition: 1s;
    transition-delay: .5s;
    transform: translateX(100px);
    opacity: 0;
  }

  .acai[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .vemmas[data-scroll] {
    transition: 1s;
    transition-delay: .75s;
    transform: translateX(100px);
    opacity: 0;
  }

  .vemmas[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.brands {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 90px;
}

.brand {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #385398;
  opacity: 0.75;
}

@media(min-width: 768px){
  .brand{
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .brands {
    flex-direction: column;
    margin-top: 25px;
  }

  .brand {
    margin: 25px 0;
  }
}

.vanshe {
  font-family: Pridi;
}

.acai {
  font-family: Quantico;
}

.vemmas {
  font-family: PT Sans;
}


.gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(99, 176, 229, 0) 0%, #63B0E5 75.48%);
}

@media (min-width: 768px) {
  .gradient{
    height: 50%;
  }
}

.main-2 {
  position: relative;
  width: 100%;
  background: white;
  color: white;
}

.content {
  padding: 120px 0;
  position: relative;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

</style>
