<template>
  <div class="main-4">
    <div class="content">
      <div class="first-row">
        <div class="first-row-text">
          <h2 class="blue-title text" data-scroll>Pasos para crear tu tienda online</h2>
          <p class="description text" data-scroll>
            1. Comenzá por dar identidad de marca a tu negocio (nombre, color y estilo de letra) y seleccioná el rubro
            al que perteneces
          </p>
          <p class="description text" data-scroll>
            2. Seleccioná el modelo de tienda que más se adapte a las características de tu negocio; editá categorías,
            galerías y cargá tus productos
          </p>
        </div>
        <img class="mates" src="./../../assets/mates.png" data-scroll>
      </div>
      <div class="second-row">
        <div class="second-row-images" data-scroll>
          <div class="help">
            <img class="chat" src="./../../assets/dialogo.png" alt="chat" />
            <div class="help-text">
              <span class="help-title">¿Necesitas<br>ayuda?</span>
              <span class="chat-with-us">
                <a href="https://www.nubing.net/contact" class="_contact">
                   Contactate con nosotros
                </a>
              </span>
            </div>
          </div>
          <img class="pencil" src="./../../assets/pencil.png" alt="pencil" data-scroll>
        </div>
        <div class="second-row-text">
          <p class="description text" data-scroll>
            3. Configurá tus medios pago, cobro, envío, facturación y conectá tus redes sociales
          </p>
          <p class="description text" data-scroll>
            4. Registrá tu dominio y comenzá a vender!
          </p>
          <p class="description-small text" data-scroll>
            Podes modificar la información y/o diseño de tu tienda cuando vos quieras. Si lo necesitas, tendras nuestra
            asistencia
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Steps'
}
</script>
<style scoped>
@media (min-width: 768px) {

  .text[data-scroll] {
    transition: 1s;
    transition-delay: 1s;
    transform: translateY(200px);
    opacity: 0;
  }

  .text[data-scroll="in"] {
    transform: translateY(0);
    opacity: 1;
  }

  .second-row-images[data-scroll] {
    transition: 1s;
    transform: translateX(-200px);
    opacity: 0;
  }

  .second-row-images[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .mates[data-scroll] {
    transition: 1s;
    transform: translateX(200px);
    opacity: 0;
  }

  .mates[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }
}

.pencil {
  margin-top: -20px;
  margin-left: -150px;
}

.help-title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #2A5393;
}

.chat-with-us a{
  font-family: Libre Franklin;
  font-style: italic;
  font-weight: 200;
  font-size: 20px;
  line-height: 36px;
  text-decoration-line: underline;
  color: #2A5393;
}

.help {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 50px;
}

.help-text {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.second-row-text {
  display: flex;
  flex-direction: column;
}

.description-small {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: rgba(51, 70, 119, 0.9);
}



.description {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  padding: 40px;
  padding-top: 50px;
  padding-left: 60px;
  margin: 0px;
  color: rgba(51, 70, 119, 0.9);
}

@media(min-width: 768px){
  .description{
    font-size: 23px;
    line-height: 160%;
  }
}

.first-row-text {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.mates {
  z-index: 1;
  width: 45%;
}

.first-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.second-row {
  display: flex;
  flex-direction: row;
}

.chat{
  width: 200px;
  margin-top: -10px;
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}


@media (max-width: 768px) {
  .first-row {
    flex-direction: column;
  }

  .first-row-text {
    width: unset;
  }

  .mates {
    width: unset;
    max-width: 100%;
  }

  .second-row {
    width: 100%;
    flex-direction: column-reverse;
  }

  .pencil {
    margin: 0;
    width: 100%;
    margin-top: -80px;
  }

  .help-text{
    margin-left: 10px;
  }

  .chat{
    width: 150px;
  }

  .help-title{
    font-size: 12px;
    line-height: 14px;
  }

  .chat-with-us a{
    font-size: 10px;
    line-height: 13px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.blue-title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
  color: #385398;
}

@media (min-width: 768px) {
  .blue-title{
    font-size: 42px;
    line-height: 55px;
  }
}

.main-4 {
  width: 100%;
  /*height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
