<template>
  <div class="main-4">
    <h2 class="blue-title" data-scroll>¿Qué podés hacer en Nubing?</h2>
    <div class="reasons">
      <div class="reason">
        <img class="image store calculator" src="../../assets/reason-calculator.png" alt="store" data-scroll>
        <span class="text title" data-scroll>Edición de<br>tienda</span>
        <span class="text description" data-scroll>Crea, edita y<br>personalizá tu tienda</span>
      </div>

      <div class="reason">
        <img class="image" src="../../assets/reason-boxes.png" alt="administration" data-scroll>
        <span class="text title" data-scroll>Administración<br>de tu negocio</span>
        <span class="text description" data-scroll>Administra y controlá<br>tus ventas y compras</span>
      </div>

      <div class="reason">
        <img class="image marketing" src="../../assets/reason-megaphone.png" alt="marketing" data-scroll>
        <span class="text title" data-scroll>Marketing<br>Digital</span>
        <span class="text description" data-scroll>Comunica y dale<br>visibilidad a tu negocio</span>
      </div>

      <div class="reason">
        <img class="image connection" src="../../assets/reason-phone.png" alt="connection" data-scroll>
        <span class="text title" data-scroll>Conexión<br>al mundo</span>
        <span class="text description" data-scroll>Conecta a redes sociales,<br>proveedores y clientes</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Reasons'
}
</script>
<style scoped>

.calculator {
  width: 200px;
  margin-top: 60px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .blue-title[data-scroll] {
    transition: 1s ease-out 1s;
    transform: translateX(-200px);
    opacity: 0;
  }

  .blue-title[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }


  .image[data-scroll] {
    transition: 1s ease-out;
    transform: translateX(200px);
    opacity: 0;
  }

  .image[data-scroll="in"] {
    transform: translateX(0);
    opacity: 1;
  }

  .text[data-scroll] {
    transition: 2s ease-out 1s;
    transform:translateX(200px);
    opacity: 0;
  }

  .text[data-scroll="in"] {
    transform:translateX(0);
    opacity: 1;
  }
}

.connection {
  margin-bottom: 20px;
  margin-top: 20px;
}

.marketing {
  margin-bottom: -15px;
  margin-top: -30px;
}

.reason {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .reason{
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.description {
  margin-top: 8px;
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: rgba(51, 70, 119, 0.9);
}

@media (min-width: 768px) {
  .description{
    font-size: 20px;
    line-height: 27px;
  }
}


.title {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(51, 70, 119, 0.9);
}

@media (min-width: 768px) {
  .title{
    font-size: 23px;
    line-height: 27px;
  }
}

.blue-title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  margin-top: 100px;
  text-align: center;
  color: #385398;
  font-size: 20px;
  line-height: 27px;
}

@media (min-width: 768px) {
  .blue-title {
    font-size: 42px;
    line-height: 55px;
  }
}

.reasons {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 1200px) {
  .reasons {
    margin-top: 0;
    flex-direction: column;
  }
}

.main-4 {
  width: 100%;
  /*height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-2 > img {
  position: absolute;
  z-index: 1;
  height: 90%;
  width: 80%;
  bottom: 0;
  right: 0;
}

h2 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 55px;
  color: white;
  margin: 0;
}

</style>
